import React from 'react';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { TiWarningOutline, TiInfoLargeOutline } from 'react-icons/ti';
import 'react-toastify/dist/ReactToastify.css';
import './toast-messages.css';

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true
});

class _BasicToast extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return <span>{this.props.message} </span>;
  }
}
const BasicToast = withTranslation()(_BasicToast);
export { BasicToast };

class _BasicError extends React.Component {
  render() {
    return <span>{this.props.message} </span>;
  }
}
const BasicError = withTranslation()(_BasicError);
export { BasicError };

class _ServiceError extends React.Component {
  render() {
    return <span>{this.props.t('error_messages.service_error')} </span>;
  }
}
const ServiceError = withTranslation()(_ServiceError);
export { ServiceError };

class _MapError extends React.Component {
  render() {
    return <span>{this.props.t('error_messages.map_error')} </span>;
  }
}
const MapError = withTranslation()(_MapError);
export { MapError };

class _BuildingRingError extends React.Component {
  render() {
    return <span>{this.props.t('error_messages.building_ring_error')} </span>;
  }
}
const BuildingRingError = withTranslation()(_BuildingRingError);
export { BuildingRingError };

class _GeocodeError extends React.Component {
  render() {
    return <span>{this.props.t('error_messages.geocode_error')} </span>;
  }
}
const GeocodeError = withTranslation()(_GeocodeError);
export { GeocodeError };

class _ReportError extends React.Component {
  render() {
    return <span>{this.props.t('error_messages.geocode_error')} </span>;
  }
}
const ReportError = withTranslation()(_ReportError);
export { ReportError };
