import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { SCOPES } from './config';
import NotFound from '../components/pages/404';

// arrayOfRequiredScopes includes an array of an array of single scopes
// so you can define different sets of scopes to allow a route
const routeIsAllowed = (userScopes, arrayOfRequiredScopes) => {
  if (userScopes === undefined) {
    return false;
  }
  if (userScopes.includes(SCOPES.all)) {
    return true;
  }
  if (arrayOfRequiredScopes === undefined) {
    return true;
  }

  let allowedScopesTuple = [];
  for (let requiredScope of arrayOfRequiredScopes) {
    allowedScopesTuple = [...allowedScopesTuple, requiredScope.map((r) => userScopes.includes(r)).every((_) => _)];
  }
  return allowedScopesTuple.some((_) => _);
};

export const PrivateRouteComponent = ({
  config,
  component: Component,
  requiredScopes,
  render,
  authenticated,
  userPasswordChangeRequired,
  userScopes,
  currentPath,
  ...rest
}) => {
  const renderIfAuthenticated = (props) =>
    userPasswordChangeRequired && currentPath !== config.passwordChangePath ? (
      <Redirect to={{ pathname: config.passwordChangePath, state: { from: props.location } }} />
    ) : !routeIsAllowed(userScopes, requiredScopes) ? (
      <NotFound notAllowed location={props.location} />
    ) : !!render ? (
      render(props)
    ) : (
      <Component {...props} />
    );

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          renderIfAuthenticated(props)
        ) : (
          <Redirect to={{ pathname: config.loginPagePath, state: { from: props.location } }} />
        )
      }
    />
  );
};

PrivateRouteComponent.propTypes = {
  component: PropTypes.element,
  render: PropTypes.func,
  authenticated: PropTypes.bool.isRequired,
  userPasswordChangeRequired: PropTypes.bool,
  userScopes: PropTypes.arrayOf(PropTypes.string),
  requiredScopes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  currentPath: PropTypes.string
};

function mapStateToProps({ authentication, router }) {
  return {
    authenticated: !!authentication.profile,
    userPasswordChangeRequired: !!authentication.profile ? authentication.profile.password_change_required : false,
    userScopes: authentication?.profile?.scopes,
    currentPath: router.location.pathname
  };
}

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);
