import {
  LOGIN_FAILURE,
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  LOGOUT_FINISHED,
  LOGOUT_REQUESTED,
  PROFILE_FAILURE,
  PROFILE_REQUESTED,
  PROFILE_SUCCESS
} from '../reducers/authentication';
import AuthenticationService from '../services/authentication';
import React from 'react';
import { toast } from 'react-toastify';
import { ServiceError } from '../components/layout/toast-messages';

export const fetchProfile = () => {
  return (dispatch, getState) => {
    if (getState().profileRequested) {
      return;
    }

    dispatch({
      type: PROFILE_REQUESTED
    });

    AuthenticationService.fetchProfile()
      .then((profile) => {
        dispatch({
          type: PROFILE_SUCCESS,
          profile
        });
      })
      .catch((error) => {
        dispatch({
          type: PROFILE_FAILURE,
          failure: 'UNAUTHORIZED'
        });
      });
  };
};

export const login = (username, password) => {
  return (dispatch, getState) => {
    if (getState().loginRequested) {
      return;
    }

    dispatch({
      type: LOGIN_REQUESTED
    });

    AuthenticationService.login(username, password)
      .then((result) => {
        dispatch({
          type: LOGIN_SUCCESS
        });

        dispatch(fetchProfile());
      })
      .catch((error) => {
        if (error.response === undefined || (error.response.status !== 400 && error.response.status !== 401)) {
          toast.error(<ServiceError />);
        }
        dispatch({
          type: LOGIN_FAILURE,
          failure: error
        });
      });
  };
};

export const logout = () => {
  return (dispatch, getState) => {
    if (getState().logoutRequested) {
      return;
    }

    dispatch({
      type: LOGOUT_REQUESTED
    });

    AuthenticationService.logout().finally(() => {
      dispatch({
        type: LOGOUT_FINISHED
      });
    });
  };
};
