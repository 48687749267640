import React from 'react';
import { withTranslation } from 'react-i18next';
import SkenDataLogo from '../../assets/images/Skendata_Logo.svg';

import styles from './footer.module.scss';
import { Col, Row } from 'reactstrap';
import Img from './img';

const BlankLink = (props) => {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

const Footer = (props) => {
  /** sets the current copyright year automatically
   *  use a minimum year as default if the customer browser is not timesynced
   */
  const minYear = 2021;
  const currentYear = new Date().getFullYear();
  const copyright = `© 2014 - ${currentYear < minYear ? minYear : currentYear}, Skendata GmbH.`;
  const versionHint = { title: `Version: ${process.env.REACT_APP_GIT_SHA}` };
  const BOOTSTRAP_CENTER_CLASS = 'align-self-center';
  return (
    <Row className={styles.footer}>
      <Col {...versionHint} className={`${BOOTSTRAP_CENTER_CLASS} offset-md-1`}>
        <BlankLink href="https://www.skendata.de">
          <Img src={SkenDataLogo} width={'156px'} height={'20px'} />
        </BlankLink>
      </Col>
      <Col {...versionHint} className={BOOTSTRAP_CENTER_CLASS}>
        {copyright}
      </Col>
      <Col className={`${styles.imprint} ${BOOTSTRAP_CENTER_CLASS}`}>
        <BlankLink href="https://www.wert14.de/impressum">{props.t('footer.imprint')}</BlankLink>
        <BlankLink href={'https://www.wert14.de/datenschutz'}>{props.t('footer.privacy_policy')}</BlankLink>
        <BlankLink href="https://www.wert14.de/nutzung">{props.t('footer.conditions')}</BlankLink>
      </Col>
    </Row>
  );
};

export default withTranslation()(Footer);
