import Fetch from './fetch';

const AuthenticationService = {
  fetchProfile: () => {
    return Fetch.api()
      .get('/v1/auth/profile')
      .then((response) => response.data);
  },

  login: (username, password) => {
    return Fetch.www().post('/api/v1/auth/login', { username, password });
  },

  logout: () => {
    return Fetch.www().post('/api/v1/auth/logout');
  },

  changePassword: (old_password, new_password, repeat_password) => {
    let bodyFormData = new FormData();
    bodyFormData.append('oldPW', old_password);
    bodyFormData.append('newPW', new_password);
    bodyFormData.append('newPW2', repeat_password);

    return Fetch.www()
      .post('/settings/updatePW', bodyFormData)
      .then((response) => {
        if (response.data === 'true' || response.data === true) {
          return true;
        }
        if (response.data.includes('mindestens 6 Zeichen')) {
          throw new Error('error_message_length');
        }
        if (response.data.includes('Passwort ist leider Falsch')) {
          throw new Error('error_message_old_password');
        }
        throw new Error('error_message_undefined');
      });
  }
};

export default AuthenticationService;
