import React, { useContext, useEffect, useState } from 'react';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  Dropdown
} from 'reactstrap';

import Wert14Logo from '../../assets/images/Wert14_Logo.svg';
import SkenDataTagline from '../../assets/images/SkenData_Tagline.svg';

import UserIcon from '../../assets/images/user.svg';
import HelpIcon from '../../assets/images/help-circle.svg';
import HomeIcon from '../../assets/images/home.svg';
import LogOutIcon from '../../assets/images/log-out.svg';
import EditIcon from '../../assets/images/edit-3.svg';
import FlagEn from '../../assets/images/flag_en.svg';
import FlagDe from '../../assets/images/flag_de.svg';

import Img from './img';
import PropTypes from 'prop-types';
import Footer from './footer';
import { connect } from 'react-redux';
import { logout } from '../../actions/authentication';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import './app-shell.scss';
import { BuildConfigContext } from '../../builds';
import { BasicError } from './toast-messages';
import { isIE } from '../../utils/browser';
import i18n from 'i18next';

const Brand = (props) => {
  const config = useContext(BuildConfigContext);
  const theme = config.userDefinedTheme(props.userProfile);

  if (!!theme.extraBrandLogo) {
    return (
      <Link className="navbar-brand" to="/">
        <Img className="extra-logo" width="120px" height="40px" src={theme.extraBrandLogo} alt="Customer Logo" />

        <div className="separator" />

        <Img className="default-logo" width="140px" height="40px" src={SkenDataTagline} alt="Wert14 Logo" />
      </Link>
    );
  }

  return (
    <Link className="navbar-brand" to="/">
      <Img className="default-logo" width="190px" height="40px" src={SkenDataTagline} alt="Wert14 Logo" />
    </Link>
  );
};

Brand.propTypes = {
  userProfile: PropTypes.object
};

const UserDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const config = useContext(BuildConfigContext);
  const displayName = props.profile ? props.profile.first_name + ' ' + props.profile.last_name : 'pad-bot-3';
  const className = isOpen ? 'pad-bot-3' : 'pad-bot-3 list-group-item-secondary';
  const languageSwitchConfig =
    i18n.language === 'de'
      ? {
          i18nLanguage: 'en',
          icon: FlagEn,
          text: 'English'
        }
      : {
          i18nLanguage: 'de',
          icon: FlagDe,
          text: 'Deutsch'
        };
  if (!props.profile) {
    return null;
  }
  return (
    <Dropdown
      nav
      inNavbar
      className={className}
      isOpen={isOpen}
      toggle={() => {
        setIsOpen(!isOpen);
      }}
    >
      <DropdownToggle nav caret>
        <Img className="nav-link-img" height="25px" width="25px" src={UserIcon} />
        SkenData
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem tag={Link} to={config.passwordChangePath}>
          <span className="nav-link">
            <Img className="nav-link-img" height="15px" width="15px" src={EditIcon} />
            {props.t('change_password.pageTitle')}
          </span>
        </DropdownItem>
        <DropdownItem onClick={() => i18n.changeLanguage(languageSwitchConfig.i18nLanguage)}>
          <span className="nav-link">
            <Img className="nav-link-img" height="15px" width="15px" src={languageSwitchConfig.icon} />
            {languageSwitchConfig.text}
          </span>
        </DropdownItem>
        <DropdownItem
          onClick={(e) => {
            e.preventDefault();
            props.onLogout();
          }}
          className="nav-link"
          style={{ color: 'rgba(0, 0, 0, 0.5)' }}
        >
          <Img className="nav-link-img" height="15px" width="15px" src={LogOutIcon} />
          Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

UserDropdown.propTypes = {
  onLogout: PropTypes.func.isRequired,
  profile: PropTypes.object
};

// https://fkhadra.github.io/react-toastify/lazy-container-and-multi-container#lazy-toastcontainer
toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: false,
  rtl: false,
  pauseOnVisibilityChange: true,
  draggable: true,
  pauseOnHover: true
});

const AppShellComponent = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const config = useContext(BuildConfigContext);

  if (isIE) {
    useEffect(() => {
      toast.warn(
        <BasicError
          message={
            'Ab dem 01.01.2022 wird der Internet Explorer (IE) 11 nicht mehr unterstützt. Bitte verwenden Sie einen anderen aktuellen Browser für die Nutzung von Wert14 (z.B. Google Chrome, Mozilla Firefox, Microsoft Edge, Safari).'
          }
        />,
        { autoClose: 10000, toastId: 'ie11_warning_toast' }
      );
    }, []);
  }

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="app-shell">
      <Navbar color="light" light expand="sm">
        <Brand userProfile={props.userProfile} />
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto navbar-links" navbar>
            {config.navbarEntities.includes('dashboard') && (
              <NavItem>
                <Link className="nav-link" to="/">
                  <Img className="nav-link-img" height="25px" width="25px" src={HomeIcon} />
                  Dashboard
                </Link>
              </NavItem>
            )}
            {config.navbarEntities.includes('support') && (
              <NavItem>
                <Link
                  onClick={() => {
                    if (props.config?.externalSupportUri) {
                      window.open(props.config?.externalSupportUri);
                    }
                  }}
                  className="nav-link"
                  to={config?.externalSupportUri ? '#' : '/support'}
                >
                  <Img className="nav-link-img" height="25px" width="25px" src={HelpIcon} />
                  Support
                </Link>
              </NavItem>
            )}
            <UserDropdown
              t={props.t}
              profile={props.userProfile}
              onLogout={() => {
                props.dispatch(logout());
              }}
            />
          </Nav>
        </Collapse>
      </Navbar>
      <div className="app-shell-content">{props.children}</div>
      <Footer />
    </div>
  );
};

AppShellComponent.propTypes = {
  children: PropTypes.any.isRequired
};

function mapStateToProps({ authentication }) {
  return {
    userProfile: authentication.profile
  };
}

const AppShell = withTranslation()(connect(mapStateToProps)(AppShellComponent));

export default AppShell;
