import { BuildConfig } from '../builds';
import Axios from 'axios';
import store from '../utils/store';
import { LOGOUT_FINISHED } from '../reducers/authentication';

/**
 * A utility component around the axios http client library.
 *
 * Please refer to the documentation https://github.com/axios/axios for usage instructions
 */
class Fetcher {
  api() {
    if (!this.__api) {
      this.__api = Fetcher.__createInstance(BuildConfig.baseUrlAPI(), true);
    }

    return this.__api;
  }

  www() {
    if (!this.__www) {
      this.__www = Fetcher.__createInstance(BuildConfig.baseUrlWWW(), true);
    }

    return this.__www;
  }

  // noinspection JSMethodCanBeStatic
  custom(baseURL) {
    return Fetcher.__createInstance(baseURL);
  }

  static __createInstance(baseUrl, intercept401 = false) {
    let instance = Axios.create({
      baseURL: baseUrl,
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      withCredentials: true
    });

    if (intercept401) {
      instance.interceptors.response.use(
        (response) => response,
        (error) => {
          // Logout the user if a 401 has been received
          if (error.response && error.response.status === 401) {
            store.dispatch({
              type: LOGOUT_FINISHED
            });
          }

          return Promise.reject(error);
        }
      );
    }

    return instance;
  }
}

const Fetch = new Fetcher();

export default Fetch;
