export const PROFILE_REQUESTED = 'authentication/PROFILE_REQUESTED';
export const PROFILE_SUCCESS = 'authentication/PROFILE_SUCCESS';
export const PROFILE_FAILURE = 'authentication/PROFILE_FAILURE';

export const LOGIN_REQUESTED = 'authentication/LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'authentication/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'authentication/LOGIN_FAILURE';

export const LOGOUT_REQUESTED = 'authentication/LOGOUT_REQUESTED';
export const LOGOUT_FINISHED = 'authentication/LOGOUT_FINISHED';

const initialState = {
  profile: null,
  profileRequested: false,
  profileFailure: null,

  loginRequested: false,
  loginFailure: null,

  logoutRequested: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_REQUESTED:
      return {
        ...state,
        profileRequested: true
      };

    case PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.profile,
        profileRequested: false
      };

    case PROFILE_FAILURE:
      return {
        ...state,
        profile: null,
        profileRequested: false,
        profileFailure: action.failure
      };

    case LOGIN_REQUESTED:
      return {
        ...state,
        loginRequested: true
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loginRequested: false,
        loginFailure: null
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        loginRequested: false,
        loginFailure: action.failure
      };

    case LOGOUT_REQUESTED:
      return {
        ...state,
        logoutRequested: true
      };

    case LOGOUT_FINISHED:
      return {
        ...state,
        logoutRequested: false,
        profile: null,
        profileRequested: false,
        profileFailure: null
      };

    default:
      return state;
  }
};
