import { BuildConfigType, Theme, UserTheme } from './types';

let baseDomain = (hostname: string) => {
  if (hostname.includes('.customer.')) {
    hostname = hostname.replace(/[^.]*\./, '');
  }
  return hostname.replace(/[^.]*\./, '');
};

export const SCOPES = Object.freeze({
  all: 'w14-product:all',
  wert14_valuation: 'w14-product:wert14_valuation',
  plausibility_check: 'w14-product:plausibility_check',
  plausibility_check_az: 'w14-product:plausibility_check:az',
  wert14_automated: 'w14-product:wert14_automated',
  wert14_automated_eo: 'w14-product:wert14_automated:eo',
  wert14_living: 'w14-product:wert14_living',
  insurance_simple: 'w14-product:insurance:simple',
  insurance_avatar: 'w14-product:insurance:avatar',
  insurance_map: 'w14-product:insurance:map'
});

export const DefaultBuildConfig: BuildConfigType = {
  baseUrlAPI: () => `https://api.${baseDomain(window.location.hostname)}`,
  baseUrlWWW: () => `https://www.${baseDomain(window.location.hostname)}`,
  loginPagePath: '/login',
  passwordChangePath: '/page/change-password',
  apiKeyGoogleMaps: 'AIzaSyDLS4mvZXtJLRVFJvPCR7LhjpPMB2yBRRo',
  navbarEntities: ['dashboard', 'support'],
  externalSupportUri: 'https://online.serviceocean.de/de/booking/form/09383b28-1020-4864-bc9b-ce72405663eb',
  externalNewsletterUri: 'https://app.vbout.com/skendata-gmbh/f/57645',
  rssFeedUri: 'https://www.wert14.de/feed/json',
  themes: {
    default: {
      extraBrandLogo: undefined,
      pageHeaderBackgroundColor: '#a3060a'
    },
    'w14-product:plausibility_check:az': {
      extraBrandLogo: `${process.env.PUBLIC_URL}/logos/logo_allianz.svg`,
      pageHeaderBackgroundColor: '#29609F'
    }
  },
  userDefinedTheme: function (userProfile: { scopes?: Array<String> }) {
    const specialCustomerThemeByScope = UserTheme.AZ;
    const hasCustomTheme =
      (userProfile?.scopes ?? []).includes(specialCustomerThemeByScope) && specialCustomerThemeByScope in this.themes;
    return hasCustomTheme ? this.themes[specialCustomerThemeByScope] : this.themes[UserTheme.DEFAULT];
  }
};
