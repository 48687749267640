import React from 'react';

const NotFound = ({ location, notAllowed }) => {
  if (notAllowed) {
    console.log(`access to ${location.pathname} is not allowed`);
  }
  return (
    <div
      style={{
        backgroundColor: '#e3e3e3',
        height: '75vh',
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: '26px',
        color: '#6b6f71'
      }}
    >
      <span
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          fontSize: '130px',
          lineHeight: '130px',
          color: '#fff'
        }}
      >
        404
      </span>
      Ooops, Page {location.pathname} not found!
    </div>
  );
};

export default NotFound;
