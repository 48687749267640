import React from 'react';
import PropTypes from 'prop-types';

import './loading-component.css';
import './load-awesome.css';

export const Spinner = (props) => {
  return (
    <div
      onClick={props.onClick}
      data-cy="spinner"
      className={['loadingBall la-ball-clip-rotate', props.dontStick ? '' : 'sticky'].join(' ')}
      style={{ ...props.style }}
    >
      <div></div>
    </div>
  );
};

export const SmallSpinner = (props) => {
  return (
    <div
      data-cy="smallSpinner"
      className={['loadingBall la-ball-clip-rotate', props.className ?? '', props.dontStick ? '' : 'sticky'].join(' ')}
      style={{ ...props.style }}
    >
      <div style={{ width: '1rem', height: '1rem' }}></div>
    </div>
  );
};

class LoadingComponent extends React.Component {
  render() {
    let displaySpinner = this.props.isLoading && !this.props.hideSpinner ? 'block' : 'none';

    return (
      <div className="loadingContainer">
        <Spinner
          dontStick={this.props.dontStick}
          style={{ top: this.props.iconTop ? this.props.iconTop : '50%', display: displaySpinner }}
        />
        <LoadingOverlay isLoading={this.props.isLoading} hideOverlay={this.props.hideOverlay}>
          {this.props.children}
        </LoadingOverlay>
      </div>
    );
  }
}

LoadingComponent.propTypes = {
  isLoading: PropTypes.bool,
  hideSpinner: PropTypes.bool,
  iconTop: PropTypes.string
};

export default LoadingComponent;

class LoadingOverlay extends React.Component {
  render() {
    let overlay = this.props.isLoading && !this.props.hideOverlay ? 'OverlayVisible' : '';
    return <div className={overlay}>{this.props.children}</div>;
  }
}

export { LoadingOverlay };
