export const SET_PROCESSES = 'wert14_automated/SET_PROCESSES';
export const SELECT_PROCESS = 'wert14_automated/SELECT_PROCESS';
export const SET_VALUATION_RESPONSE = 'wert14_automated/SET_VALUATION_RESPONSE';

const initialState = {
  processes: [],
  selectedProcess: null,
  valuationResponseMap: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PROCESSES:
      return {
        ...state,
        processes: action.processes
      };
    case SELECT_PROCESS:
      return {
        ...state,
        selectedProcess: action.processId
      };
    case SET_VALUATION_RESPONSE:
      const processId = action.valuationResponse.process.process_id;
      let update = action.valuationResponse;

      // if we get a partial response, use all current state buildings and patch the new onces
      if (action.valuationResponse.is_partial_response && state.valuationResponseMap[processId]) {
        update = {
          ...action.valuationResponse,
          buildings: { ...state.valuationResponseMap[processId].buildings, ...action.valuationResponse.buildings }
        };
      }

      return {
        ...state,
        valuationResponseMap: {
          ...state.valuationResponseMap,
          [processId]: update
        }
      };
    default:
      return state;
  }
};
