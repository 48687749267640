export enum UserTheme {
  DEFAULT = 'default',
  AZ = 'w14-product:plausibility_check:az'
}

export interface Theme {
  extraBrandLogo?: string;
  pageHeaderBackgroundColor: string;
}

export interface BuildConfigType {
  baseUrlAPI: () => string;
  baseUrlWWW: () => string;
  loginPagePath: string;
  passwordChangePath: string;
  apiKeyGoogleMaps: string;
  navbarEntities: Array<string>;
  externalSupportUri: string;
  externalNewsletterUri: string;
  rssFeedUri: string;
  userDefinedTheme: (userProfile: { scopes?: Array<String> }) => Theme;
  themes: Record<UserTheme, Theme>;
}
