import { initReactI18next } from 'react-i18next';
import common_en from '../translations/en/common.json';
import common_de from '../translations/de/common.json';
import building_de from '../translations/de/building.json';
import building_en from '../translations/en/building.json';
import LanguageDetector from 'i18next-browser-languagedetector';

import i18n from 'i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS: 'common',
    resources: {
      en: {
        common: common_en,
        building: building_en
      },
      de: {
        common: common_de,
        building: building_de
      }
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      lookupQuerystring: 'language',
      lookupCookie: 'language',
      lookupLocalStorage: 'language',
      caches: ['cookie', 'localStorage']
    }
  });

export default i18n;
