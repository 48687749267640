import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from '../utils/store';

import AppShell from '../components/layout/app-shell';
import { Route, Switch } from 'react-router';

import './../index.scss';
import { DefaultBuildConfig, SCOPES } from '../utils/config';
import NotFound from '../components/pages/404';
import { PrivateRoute } from '../utils/private-route';
import { Spinner } from '../components/loading-component';

const Dashboard = lazy(() => import('../components/pages/dashboard'));
const PlausibilityCheckPage = lazy(() => import('../components/pages/plausibility-check'));
const BuildingSelection = lazy(() => import('../components/pages/wert14/building-selection'));
const ListImport = lazy(() => import('../components/pages/wert14/list-import'));
const BuildingsListView = lazy(() => import('../components/pages/wert14/list-import/buildings-list-view'));
const LivingPage = lazy(() => import('../components/pages/living'));

const ContractList = lazy(() => import('../components/pages/wert14/contract-list'));
const BuildingCalculation = lazy(() => import('../components/pages/wert14/building-calculation'));

const InsuranceRouter = lazy(() => import('../components/pages/insurance/Router'));
const Support = lazy(() => import('../components/pages/support'));
const LoginPage = lazy(() => import('../components/pages/login'));
const ChangePasswordPage = lazy(() => import('../components/pages/change-password'));

const BuildConfig = {
  ...DefaultBuildConfig,
  loginPagePath: '/page/login'
};

const TargetElement = document.querySelector('#root');
export const BuildConfigContext = React.createContext(BuildConfig);

const Element = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <BuildConfigContext.Provider value={BuildConfig}>
        <AppShell config={BuildConfig}>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <PrivateRoute
                exact
                path="/"
                config={BuildConfig}
                requiredScopes={[[]]}
                render={() => <Dashboard config={BuildConfig} />}
              />
              <PrivateRoute
                exact
                path="/page/plausibility/:process_id?"
                config={BuildConfig}
                requiredScopes={[[SCOPES.plausibility_check], [SCOPES.plausibility_check_az]]}
                render={({ match }) => (
                  <PlausibilityCheckPage config={BuildConfig} initialProcessId={match?.params?.process_id} />
                )}
              />
              <PrivateRoute
                exact
                path="/page/wert14/building-selection"
                config={BuildConfig}
                requiredScopes={[[SCOPES.wert14_valuation]]}
                render={() => <BuildingSelection config={BuildConfig} />}
              />
              <PrivateRoute
                exact
                path="/page/wert14/contract-list"
                config={BuildConfig}
                requiredScopes={[[SCOPES.wert14_valuation]]}
                render={() => <ContractList config={BuildConfig} />}
              />
              <PrivateRoute
                exact
                path="/page/wert14/building-calculation"
                config={BuildConfig}
                requiredScopes={[[SCOPES.wert14_valuation]]}
                render={() => <BuildingCalculation config={BuildConfig} />}
              />
              <PrivateRoute
                exact
                path="/page/wert14/wowi"
                config={BuildConfig}
                requiredScopes={[[SCOPES.wert14_automated]]}
                render={() => <ListImport config={BuildConfig} scope={SCOPES.wert14_automated} />}
              />
              <PrivateRoute
                exact
                path="/page/wert14/wowi/:id"
                config={BuildConfig}
                requiredScopes={[[SCOPES.wert14_automated]]}
                render={() => <BuildingsListView config={BuildConfig} scope={SCOPES.wert14_automated} />}
              />
              <PrivateRoute
                exact
                path="/page/wert14/automated"
                config={BuildConfig}
                requiredScopes={[[SCOPES.wert14_automated_eo]]}
                render={() => <ListImport config={BuildConfig} scope={SCOPES.wert14_automated_eo} />}
              />
              <PrivateRoute
                exact
                path="/page/wert14/automated/:id"
                config={BuildConfig}
                requiredScopes={[[SCOPES.wert14_automated_eo]]}
                render={() => <BuildingsListView config={BuildConfig} scope={SCOPES.wert14_automated_eo} />}
              />
              <PrivateRoute
                exact
                path="/page/wert14/living"
                config={BuildConfig}
                requiredScopes={[[SCOPES.wert14_living]]}
                render={() => <LivingPage config={BuildConfig} />}
              />
              <PrivateRoute
                exact
                path="/page/insurance/*"
                config={BuildConfig}
                requiredScopes={[[]]}
                render={() => <InsuranceRouter config={BuildConfig} />}
              />

              <PrivateRoute
                exact
                path="/support"
                config={BuildConfig}
                requiredScopes={[[]]}
                render={() => <Support config={BuildConfig} />}
              />

              <Route
                exact
                path={BuildConfig.loginPagePath}
                render={(props) => <LoginPage config={BuildConfig} {...props} />}
              />
              <PrivateRoute
                exact
                config={BuildConfig}
                requiredScopes={[[]]}
                path={BuildConfig.passwordChangePath}
                render={(props) => <ChangePasswordPage config={BuildConfig} {...props} />}
              />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </AppShell>
      </BuildConfigContext.Provider>
    </ConnectedRouter>
  </Provider>
);

export { BuildConfig, TargetElement, Element };
