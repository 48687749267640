export const SELECT_BUILDING = 'wert14/SELECT_BUILDING';
export const INIT_VALUATION_PROCESS = 'wert14/INIT_VALUATION_PROCESS';
export const SET_CURRENTLY_PROCESSED_BUILDING = 'wert14/SET_CURRENTLY_PROCESSED_BUILDING';

const initialState = {
  selectedBuilding: null,
  valuationProcess: null,
  currentlyProcessedBuilding: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_BUILDING:
      return {
        ...state,
        selectedBuilding: action.buildingInformation
      };
    case INIT_VALUATION_PROCESS:
      return {
        ...state,
        valuationProcess: action.valuationProcess
      };
    case SET_CURRENTLY_PROCESSED_BUILDING:
      return {
        ...state,
        currentlyProcessedBuilding: {
          processId: action.processId,
          buildingId: action.buildingId
        }
      };
    default:
      return state;
  }
};
