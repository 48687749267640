import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authentication from '../reducers/authentication';
import wert14 from '../reducers/wert14';
import wert14_automated from '../reducers/wert14_automated';

export default (history) =>
  combineReducers({
    authentication,
    wert14,
    wert14_automated,
    router: connectRouter(history)
  });
