import React from 'react';
import PropTypes from 'prop-types';

const Img = (props) => {
  return (
    <div
      className={props.className}
      style={{
        backgroundImage: `url(${props.src})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: props.width,
        height: props.height,
        ...props.style
      }}
    ></div>
  );
};

Img.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  style: PropTypes.object
};

export default Img;
